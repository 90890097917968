<template>
  <div>
    <component :is="appComponent" @wheel="console.log($event)" />
  </div>
</template>

<script>
import AppLayout from "@/layout/App";
export default {
  name: "AppRoot",
  components: {
    AppLayout,
  },
  metaInfo: {
    title: "Воронки контактов платфомы Талант",
    titleTemplate: `%s | ${process.env.VUE_APP_TITLE}`,
  },
  computed: {
    layout() {
      return this.$store.state.ui.layout;
    },
    appComponent() {
      const { layout } = this;
      return layout || "router-view";
    },
  },
  created() {
    setInterval(() => {
      this.$store.commit("SET_TIME_STAMP");
    }, 30000);
  },
  errorCaptured(err) {
    console.log("err", err);
  },
  methods: {},
};
</script>
<style lang="scss" src="@/assets/scss/shared/index.scss"></style>
<style lang="scss">
.talent-toast {
  max-width: 450px;
}
</style>
