<template>
  <v-container>
    <h1>{{ $metaInfo.title }}</h1>
    <p>У вас недостаточно прав для просмотра этой страницы</p>
    <p>Обратитесь к администратору сервиса.</p>
    <div v-if="token"><a href="#" @click.prevent="handleLogout">Выйти</a></div>
  </v-container>
</template>

<script>
export default {
  name: "Page403",
  metaInfo() {
    return { title: "Ошибка доступа" };
  },
  computed: {
    token() {
      return this.$store.state.user.token;
    },
  },
  methods: {
    async handleLogout() {
      await this.$store.dispatch("user/logOut");
      this.$router.push({
        name: "login",
      });
    },
  },
};
</script>

<style></style>
