<template>
  <v-app
    id="app"
    color="primary"
    :style="{ background: $vuetify.theme.themes.light.background }"
  >
    <v-navigation-drawer
      v-model="navOpen"
      temporary
      color="grey darken-4"
      dark
      fixed
    >
      <v-list>
        <v-list-item :to="{ name: 'boards' }" exact-path>
          <v-list-item-icon>
            <v-icon>mdi-playlist-star</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Список воронок</v-list-item-title>
        </v-list-item>
        <v-list-item :to="{ name: 'tags' }" exact-path>
          <v-list-item-icon>
            <v-icon>mdi-tag</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Теги</v-list-item-title>
        </v-list-item>
        <v-list-item :to="{ name: 'search' }" exact-path>
          <v-list-item-icon>
            <v-icon>mdi-magnify</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Поиск контактов</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="isSuperUser" :to="{ name: 'users' }">
          <v-list-item-icon>
            <v-icon>mdi-account-multiple</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Пользователи CRM</v-list-item-title>
        </v-list-item>
        <v-list-item :to="{ name: 'talent_organizations' }">
          <v-list-item-icon>
            <v-icon>mdi-briefcase</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Организации</v-list-item-title>
        </v-list-item>
        <v-list-item :to="{ name: 'tags_import' }">
          <v-list-item-icon>
            <v-icon>mdi-upload</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Импорт тегов</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar color="grey darken-4" dense dark app>
      <v-app-bar-nav-icon
        v-if="user"
        @click="navOpen = !navOpen"
      ></v-app-bar-nav-icon>
      <v-toolbar-title>Talent CRM</v-toolbar-title>
      <v-spacer></v-spacer>
      <form
        v-if="user && $route.name !== 'search'"
        ref="searchForm"
        class="mr-4"
        action="/search"
        method="GET"
        @submit.prevent="handleSearch"
      >
        <v-text-field
          v-model="search"
          light
          solo
          rounded
          dense
          hide-details
          append-icon="mdi-account-search"
          placeholder="Поиск пользователей"
          autocomplete="new-password"
          @click:append="handleSearch"
        ></v-text-field>
      </form>
      <template v-if="user">
        <v-avatar size="32px" class="mr-2" color="grey darken-1">
          <v-img
            v-if="userAvatar"
            class="object-fit-cover"
            :src="userAvatar"
          ></v-img>
          <span v-else class="font-weight-bold"
            >{{ user.first_name[0] }}.{{ user.last_name[0] }}</span
          >
        </v-avatar>
        <div class="user-info">
          <div class="user-info__name">
            {{ user.first_name }} {{ user.last_name }}
          </div>
          <div class="user-info__email">{{ user.email }}</div>
        </div>
        <v-btn icon @click.prevent="handleLogout">
          <v-icon>mdi-logout</v-icon>
        </v-btn>
      </template>
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>
    <v-footer color="grey darken-3" class="mt-4">
      <v-row justify="center" no-gutters>
        <!-- <v-btn
          v-for="link in links"
          :key="link"
          color="white"
          text
          rounded
          class="my-2"
        >
          {{ link }}
        </v-btn> -->
        <v-col class="lighten-2 py-4 text-center white--text" cols="12">
          {{ new Date().getFullYear() }} —
          <strong>Платформа Кружкового движения</strong>
        </v-col>
      </v-row>
    </v-footer>
    <confirm-dialog ref="confirm"></confirm-dialog>
  </v-app>
</template>

<script>
import ConfirmDialog from "@/components/dialogs/ConfirmDialog.vue";
import { mapGetters } from "vuex";
import { pathJoin } from "@/api/utils";
export default {
  name: "AppLayout",
  components: {
    ConfirmDialog,
  },
  data() {
    return {
      search: "",
      navOpen: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "user/user",
    }),
    isSuperUser() {
      const { user } = this;
      return user?.is_superuser;
    },
    userAvatar() {
      const { user } = this;
      if (!user || !user?.avatar) return undefined;
      return pathJoin(process.env.VUE_APP_AVATAR_S3, user?.avatar);
    },
  },
  mounted() {
    this.$root.$confirm = this.$refs.confirm.open;
  },
  methods: {
    async handleLogout() {
      try {
        await this.$store.dispatch("user/logOut");
      } catch (error) {
        // void
      }
      window.location.reload();
    },
    handleSearch() {
      this.$router.push({
        name: "search",
        query: { search: this.search },
      });
      this.search = "";
    },
  },
};
</script>

<style lang="scss" scoped>
.user-block {
  min-height: 81px !important;
  align-items: flex-start !important;
}
.user-info {
  font-size: 12px;
  &__name {
    font-weight: bold;
  }

  &__email {
    opacity: 0.75;
  }
}
</style>
