import store from "@/store";
/**
 * Проверяет авторизацию пользователя
 * Чтобы исключить проверку роута, на авторизацию
 * укажите meta.authless = true
 */
export const authMiddleware = async (to, from, next) => {
  /**
   * Если роут не требует авторизации
   * то перекидываемы
   */
  if (to.meta.authless) return next();

  if (store.getters["user/isAuthorized"]) {
    return next();
  }
  /**
   * Если нет авторизационного токена
   */
  if (!store.state.user.token) {
    return next({
      name: "login",
      query: {
        next: to.fullPath,
      },
    });
  }
  /**
   * Если пользователь не авторизован,
   * то попробуем получить инфу о пользователе
   */
  try {
    await store.dispatch("user/getMe");
    return next();
  } catch (error) {
    if (error.status === 403) {
      next({
        name: "403",
      });
    } else {
      next({
        name: "login",
        query: {
          next: to.fullPath,
        },
      });
    }
  }
};

export const roleMiddleware = async (to, from, next) => {
  if (!to.meta.roles?.length) return next();
  const user = store.getters["user/user"];
  if (to.meta.roles.includes("superuser") && !user.is_superuser) {
    return next({
      name: "403",
    });
  }
  return next();
};
