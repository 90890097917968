import Vue from "vue";
import { initialListingModel } from "@/utils";
import { talentClient } from "@/api";
import { SOCIAL_PROVIDERS_SLUGS } from "@/constants";

const initialState = () => {
  return {
    socials: {
      ...initialListingModel(10),
      telegramAccounts: [],
      kaggleAccounts: [],
    },
  };
};

export default {
  namespaced: true,
  state: {
    ...initialState(),
  },
  actions: {
    async getSocialAccounts({ commit }, { provider, talentId }) {
      const url = provider
        ? `/users/${talentId}/social-auths/${provider}/`
        : `/users/${talentId}/social-auths/`;
      const accountDataName = provider ? provider + "Accounts" : "list";
      const { data } = await talentClient({
        method: "GET",
        url: url,
      });
      if (data.count) {
        let socials = data.results;
        if (provider) {
          socials = socials.map((item) => {
            return {
              ...item,
              provider,
            };
          });
        }
        commit("SET_SOCIALS_STATE", {
          [accountDataName]: socials,
        });
      }
    },
    async getAllUserSocialAccounts({ dispatch, commit }, talentId) {
      commit("SET_SOCIALS_STATE", {
        pending: true,
      });
      try {
        await Promise.all([
          dispatch("getSocialAccounts", { talentId }),
          dispatch("getSocialAccounts", {
            provider: SOCIAL_PROVIDERS_SLUGS.telegram,
            talentId,
          }),
          dispatch("getSocialAccounts", {
            provider: SOCIAL_PROVIDERS_SLUGS.kaggle,
            talentId,
          }),
        ]);
      } catch (error) {
        commit("SET_SOCIALS_STATE", {
          error: error.message,
        });
        console.log(error);
      }
      commit("SET_SOCIALS_STATE", {
        pending: false,
      });
    },
  },
  mutations: {
    RESET_STATE(state) {
      const initial = initialState();
      Object.keys(initial).forEach((key) => {
        Vue.set(state, key, initial[key]);
      });
    },
    SET_SOCIALS_STATE(state, payload) {
      state.socials = { ...state.socials, ...payload };
    },
  },
};
