import Vue from "vue";
import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";

const options = {
  // You can set your default options here
  draggable: false,
  containerClassName: "talent-toast",
};

Vue.use(Toast, options);
