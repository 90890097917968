<template>
  <v-container>
    <div class="d-md-flex d-sm-block">
      <div>
        <div class="mr-md-4 mr-xs-0 aside">
          <sub-page-routes :routes="routes" />
        </div>
      </div>

      <div class="flex-grow-1">
        <keep-alive :max="MAX_ROUTES_CACHE">
          <router-view :key="$route.fullPath" />
        </keep-alive>
      </div>
    </div>
  </v-container>
</template>

<script>
import SubPageRoutes from "@/components/SubPageRoutes.vue";
import { CONTACTS_SCOPES } from "@/constants";
export default {
  name: "Contact",
  components: {
    SubPageRoutes,
  },
  metaInfo() {
    return {
      title: `Карточка контакта`,
    };
  },
  computed: {
    routes() {
      return Object.values(CONTACTS_SCOPES);
    },
  },
  watch: {
    $route(to, from) {
      if (to.params.id !== from.params.id) {
        this.$store.commit("contact/RESET_STATE");
        this.init();
      }
    },
  },
  beforeDestroy() {
    this.$store.commit("contact/RESET_STATE");
  },
  async created() {
    this.MAX_ROUTES_CACHE = 8;
    this.init();
  },
  methods: {
    async init() {
      try {
        await this.$store.dispatch(
          "contact/getAllUserSocialAccounts",
          this.$route.params.id
        );
        await this.$store.dispatch(
          "talent/getUserById",
          +this.$route.params.id
        );
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style lang="scss">
.contact-item {
  border-bottom: 1px solid #c0c0c0;
  padding-bottom: 10px;
  margin-bottom: 10px;
  transition: background-color 0.3s;

  &:last-child {
    border-bottom: 0;
    margin-bottom: 0;
  }
  &.on-hover {
    background-color: #eee;
    cursor: pointer;
  }
}

.search-container {
  position: sticky;
  top: 60px;
  z-index: 1;
  width: 250px;
  margin-top: 4px;

  @media screen and (max-width: 961px) {
    width: 100%;
    position: static;
  }
}
.aside {
  position: sticky;
  top: 60px;
  z-index: 1;
  width: 100%;
  max-width: 200px;
  @media screen and (max-width: 959px) {
    max-width: 100%;
    margin-bottom: 20px;
    position: static;
  }
}

.label {
  width: 100%;
}
</style>
