import axios from "axios";
import { APIError } from "./utils";
import Cookies from "js-cookie";
import { ACCESS_TOKEN } from "@/constants";

export const baseURL = process.env.VUE_APP_API_URL;

let isRedirected = false;
/**
 * Клиент для обращения к API сервиса CRM
 * `baseURL` = process.env.VUE_APP_API_URL
 */
export const apiClient = axios.create({
  baseURL,
});

apiClient.interceptors.request.use((config) => {
  const token = Cookies.get(ACCESS_TOKEN);
  if (token && config) {
    config.headers["Authorization"] = "Bearer " + token;
  }
  return config;
});

apiClient.interceptors.response.use(
  (res) => res,
  async (error) => {
    const status = error.response?.status;
    const isAuthError = status === 401;
    if (isAuthError && !isRedirected && !error.config.noAuthRedirect) {
      isRedirected = true;
      window.location.pathname = "/login";
    }
    return Promise.reject(new APIError(error));
  }
);
