import { TYPE } from "vue-toastification";
const defaultOptions = {
  timeout: 5000,
  type: TYPE.ERROR,
};
export default {
  methods: {
    _showError(msg, options = {}) {
      this.$toast(msg, {
        ...defaultOptions,
        ...options,
      });
    },
  },
};
