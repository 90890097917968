import Vue from "vue";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import router from "@/router";

Sentry.init({
  Vue,
  dsn: "https://09ce0c98a6e6440abba9e704e7c7c849@logs.corp.jetstyle.in/30",
  release: `talent-crm-front@${process.env.VUE_APP_VERSION}`,
  environment: process.env.VUE_APP_SENTRY_ENVIRONMENT || "production",
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: [
        "localhost",
        "crm.test.kruzhok.org",
        "crm.kruzhok.org",
        /^\//,
      ],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.8,
});
