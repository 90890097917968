<template>
  <v-container>
    <h1>Авторизация</h1>
    <div v-if="error">
      <p>Не удалось авторизоваться. Возникла ошибка: {{ error }}</p>
      <p><router-link :to="{ name: 'login' }">Перейти ко входу</router-link></p>
    </div>
    <p v-else>Подождите немного...</p>
  </v-container>
</template>

<script>
/**
 * Страница на которую идет редирект,
 * после авторизации пользователя в таланте
 */
import { apiClient } from "@/api/client";
export default {
  name: "Auth",
  data() {
    return {
      pending: true,
      error: "",
    };
  },
  created() {
    this.authorize();
  },
  methods: {
    /**
     * Обменяем авторизационый код на токен
     */
    async authorize() {
      const code = this.$route.query.code;
      if (!code) {
        this.error = "Отсутствует обязательный параметр code.";
        this.pending = false;
        return;
      }
      try {
        const params = { code };
        /**
         * Для дев сервера, надо в месте с кодом отправить,
         * redirect_uri с которого запрашивали
         */
        if (process.env.NODE_ENV === "development") {
          params.redirect_uri = `${window.location.origin}/auth/complete`;
        }
        const { data } = await apiClient({
          method: "GET",
          url: "/auth/complete",
          params,
        });
        await this.$store.dispatch("user/setAuth", data);
        const nextUrl = localStorage?.getItem("next_redirect");
        if (nextUrl) {
          localStorage?.removeItem("next_redirect");
          this.$router.push(nextUrl);
        } else {
          this.$router.push("/");
        }
      } catch (error) {
        this.error = error.message;
      } finally {
        this.pending = false;
      }
    },
  },
};
</script>

<style></style>
