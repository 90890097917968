<template>
  <v-container>
    <h1>404</h1>
    <div>Такой страницы нет или она была удалена</div>
  </v-container>
</template>

<script>
export default {
  name: "Page404",
};
</script>

<style></style>
