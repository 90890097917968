<template>
  <v-card>
    <v-card-title class="pb-0 pb-md-2">
      <p class="text-h6 rm-dialog-title mb-0 mb-md-4">Разделы</p>
    </v-card-title>
    <v-card-actions>
      <div class="d-none d-md-flex action">
        <router-link
          v-for="tab in routes"
          :key="tab.value"
          :to="{ name: tab.value, query: $route.query }"
        >
          <v-chip
            color="primary"
            :outlined="$route.name && $route.name !== tab.value"
            label
            class="flex-grow-1 text-center label"
            :style="{
              cursor:
                $route.name && $route.name !== tab.value
                  ? 'pointer'
                  : 'default',
            }"
            >{{ tab.title }}</v-chip
          >
        </router-link>
      </div>

      <v-select
        v-model="select"
        :items="routes"
        item-text="title"
        item-value="value"
        label="Select"
        return-object
        single-line
        class="d-xs-block d-sm-block d-md-none px-2"
      ></v-select>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "SubPageRoutes",
  props: {
    routes: {
      type: Array,
      required: true,
    },
  },
  computed: {
    select: {
      get() {
        return this.routes.find((tab) => tab.value === this.$route.name);
      },
      set(val) {
        this.$router.push({ name: val.value, query: this.$route.query });
      },
    },
  },
};
</script>

<style scoped lang="scss">
.action {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
</style>
