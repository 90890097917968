import Vue from "vue";
import {
  ValidationProvider,
  extend,
  ValidationObserver,
  configure,
  localize,
} from "vee-validate";
import ru from "vee-validate/dist/locale/ru.json";
import { required, max, numeric, min, email } from "vee-validate/dist/rules";
localize("ru", ru);
const config = {
  mode: "eager",
};

extend("numeric", numeric);
extend("max", max);
extend("min", min);
extend("email", email);

extend("required", {
  ...required,
  message: "Это обязательное поле",
});

configure(config);
// Register it globally
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
