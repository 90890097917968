import { talentClient } from "@/api";

export default {
  namespaced: true,
  state: {
    organization: null,
  },
  actions: {
    async getOrganization({ commit }, id) {
      const { data } = await talentClient({
        method: "GET",
        url: `/organizations/${id}/`,
        params: {
          include_fields: "has_actual_agreement,has_approved_legal_entity",
          owned_brands: true,
          used_brands: true,
        },
      });
      if (data.id) {
        commit("SET_ORGANIZATION", data);
        return data;
      }
    },
  },
  mutations: {
    SET_ORGANIZATION(state, organization) {
      state.organization = organization;
    },
  },
};
