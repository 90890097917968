import {
  COMMON_SCOPE,
  EDUCATION_SCOPE,
  REQUESTS_SCOPE,
  PROJECTS_SCOPE,
  ACHIEVEMENTS_SCOPE,
  ORGANIZATIONS_SCOPE,
  MENTORS_SCOPE,
  USER_CONTACTS_SCOPE,
} from "../constants";

const USER_SCOPES = [
  COMMON_SCOPE,
  EDUCATION_SCOPE,
  REQUESTS_SCOPE,
  PROJECTS_SCOPE,
  ACHIEVEMENTS_SCOPE,
  ORGANIZATIONS_SCOPE,
  MENTORS_SCOPE,
  USER_CONTACTS_SCOPE,
];

export const legacyScopeRedirect = (to, form, next) => {
  const scope = to.query.scope;
  if (!scope || to.name === scope || !USER_SCOPES.includes(scope))
    return next();
  return next({
    ...to,
    name: scope,
    query: {
      ...to.query,
      scope: undefined,
    },
  });
};
