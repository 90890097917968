export default {
  namespaced: true,
  state: {
    layout: "AppLayout",
  },
  mutations: {
    SET_LAYOUT(state, payload) {
      state.layout = payload;
    },
  },
};
