<template>
  <v-container>
    <h1>Авторизация</h1>
    <p>Войдите в учетную запись Талант</p>
    <v-btn :href="loginURL" color="primary">Войти</v-btn>
  </v-container>
</template>

<script>
import { baseURL } from "@/api/client";
import { pathJoin } from "@/api/utils";
export default {
  name: "Login",
  computed: {
    loginURL() {
      let url = pathJoin(baseURL, "/auth/redirect");
      /**
       * По-умолчанию редирект идет на `stage`,
       * передаем редирект в явном виде, чтобы отправлял
       * на dev-сервер
       */
      if (process.env.NODE_ENV === "development") {
        url += `?redirect_uri=${window.location.origin}/auth/complete`;
      }
      return url;
    },
  },
  created() {
    const nextURL = this.$route.query.next;
    if (nextURL) {
      localStorage?.setItem("next_redirect", nextURL);
    }
  },
};
</script>

<style></style>
